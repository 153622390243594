module.exports = {
  fr: {
    default: true,
    path: `fr`,
    locale: `fr`
  },
  en: {
    default: false,
    path: `en`,
    locale: `en-GB`
  },
};
